
export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans',
        name: 'admin.price-plans.index',
        component: () => import('../views/admin/price-plans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/create',
        name: 'admin.price-plans.create',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/:id',
        name: 'admin.price-plans.edit',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.show',
        component: () => import('../views/admin/users/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/companies',
        name: 'admin.companies.index',
        component: () => import('../views/admin/companies/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/companies/:id',
        name: 'admin.companies.show',
        component: () => import('../views/admin/companies/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/consultations',
        name: 'admin.consultations.index',
        component: () => import('../views/admin/consultations/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/consultations/:id',
        name: 'admin.consultations.show',
        component: () => import('../views/admin/consultations/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/assessments',
        name: 'admin.assessments.index',
        component: () => import('../views/admin/assessments/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/assessments/create',
        name: 'admin.assessments.create',
        component: () => import('../views/admin/assessments/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/chats',
        name: 'admin.chats.index',
        component: () => import('../views/admin/chats/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/assessments/:id',
        name: 'admin.assessments.show',
        component: () => import('../views/admin/assessments/Show.vue'),
        meta: {
            auth: 'admin'
        }
    }
];
